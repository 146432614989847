export default {
  paginationActivePage: (state) => state.paginationActivePage,
  paginationPagesCount: (state) => state.paginationPagesCount,
  selectedAppStatus: (state) => state.selectedAppStatus,
  selectedAppPeriod: (state) => state.selectedAppPeriod,
  incomeApps: (state) => state.incomeApps,
  apps: (state) => state.apps,
  incomeAppsWithPagination: (state) => state.incomeAppsWithPagination,
  appsWithPagination: (state) => state.appsWithPagination
};
