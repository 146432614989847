const middleware = {}

middleware['isApplicant'] = require('../uicore/middleware/isApplicant.js')
middleware['isApplicant'] = middleware['isApplicant'].default || middleware['isApplicant']

middleware['isApplicantLoggedIn'] = require('../uicore/middleware/isApplicantLoggedIn.js')
middleware['isApplicantLoggedIn'] = middleware['isApplicantLoggedIn'].default || middleware['isApplicantLoggedIn']

middleware['isEmployee'] = require('../uicore/middleware/isEmployee.js')
middleware['isEmployee'] = middleware['isEmployee'].default || middleware['isEmployee']

middleware['isEmployeeLoggedIn'] = require('../uicore/middleware/isEmployeeLoggedIn.js')
middleware['isEmployeeLoggedIn'] = middleware['isEmployeeLoggedIn'].default || middleware['isEmployeeLoggedIn']

middleware['isOrgAdmin'] = require('../uicore/middleware/isOrgAdmin.js')
middleware['isOrgAdmin'] = middleware['isOrgAdmin'].default || middleware['isOrgAdmin']

middleware['isSystemAdmin'] = require('../uicore/middleware/isSystemAdmin.js')
middleware['isSystemAdmin'] = middleware['isSystemAdmin'].default || middleware['isSystemAdmin']

middleware['redirects'] = require('../uicore/middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

export default middleware
