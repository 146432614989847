import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _21d8717a = () => interopDefault(import('../modules/new_geoportal/pages/base/geoportal/index.vue' /* webpackChunkName: "" */))
const _ba71ea48 = () => interopDefault(import('../modules/new_geoportal/pages/arm/geoportal/index.vue' /* webpackChunkName: "" */))
const _5d439252 = () => interopDefault(import('../modules/AstanaEgkn/pages/arm/statistica.vue' /* webpackChunkName: "" */))
const _7f3751be = () => interopDefault(import('../modules/AstanaEgkn/pages/arm/registry.vue' /* webpackChunkName: "" */))
const _1ba686d4 = () => interopDefault(import('../modules/AstanaEgkn/pages/arm/contacts.vue' /* webpackChunkName: "" */))
const _a348ac30 = () => interopDefault(import('../uicore/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _66db2f38 = () => interopDefault(import('../uicore/pages/base/check_doc/_fileId.vue' /* webpackChunkName: "pages/base/check_doc/_fileId" */))
const _6cffd496 = () => interopDefault(import('../uicore/pages/arm/resetPass/_token.vue' /* webpackChunkName: "pages/arm/resetPass/_token" */))
const _3542be41 = () => interopDefault(import('../uicore/pages/base/service/list/_parentId.vue' /* webpackChunkName: "pages/base/service/list/_parentId" */))
const _5d90ff92 = () => interopDefault(import('../uicore/pages/base/service/info/_tsrId.vue' /* webpackChunkName: "pages/base/service/info/_tsrId" */))
const _236bd4e8 = () => interopDefault(import('../uicore/pages/base/auth/resetPass/_token.vue' /* webpackChunkName: "pages/base/auth/resetPass/_token" */))
const _663a116e = () => interopDefault(import('../uicore/pages/base/application/process/_asId.vue' /* webpackChunkName: "pages/base/application/process/_asId" */))
const _205d6bb6 = () => interopDefault(import('../uicore/pages/base/application/monitor/_appId.vue' /* webpackChunkName: "pages/base/application/monitor/_appId" */))
const _b2f92bcc = () => interopDefault(import('../uicore/pages/arm/service/list/_parentId.vue' /* webpackChunkName: "pages/arm/service/list/_parentId" */))
const _53468a59 = () => interopDefault(import('../uicore/pages/arm/service/info/_tsrId.vue' /* webpackChunkName: "pages/arm/service/info/_tsrId" */))
const _21c110d0 = () => interopDefault(import('../uicore/pages/arm/application/process/_asId.vue' /* webpackChunkName: "pages/arm/application/process/_asId" */))
const _36b6617e = () => interopDefault(import('../uicore/pages/arm/application/monitor/_appId.vue' /* webpackChunkName: "pages/arm/application/monitor/_appId" */))
const _f480b7cc = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/_tsrId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/_tsrId" */))
const _1d46534a = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/employee/_empId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/employee/_empId" */))
const _6ea44f1d = () => interopDefault(import('../uicore/pages/base/application/result/appId.vue' /* webpackChunkName: "pages/base/application/result/appId" */))
const _0d2c313b = () => interopDefault(import('../uicore/pages/arm/application/view/asId.vue' /* webpackChunkName: "pages/arm/application/view/asId" */))
const _480dec96 = () => interopDefault(import('../uicore/pages/arm/admin/system/route.vue' /* webpackChunkName: "pages/arm/admin/system/route" */))
const _732d3561 = () => interopDefault(import('../uicore/pages/arm/admin/system/list.vue' /* webpackChunkName: "pages/arm/admin/system/list" */))
const _5a22872c = () => interopDefault(import('../uicore/pages/arm/admin/system/control.vue' /* webpackChunkName: "pages/arm/admin/system/control" */))
const _4d7ebafe = () => interopDefault(import('../uicore/pages/arm/admin/system/calendar.vue' /* webpackChunkName: "pages/arm/admin/system/calendar" */))
const _55de91ae = () => interopDefault(import('../uicore/pages/arm/admin/system/app.vue' /* webpackChunkName: "pages/arm/admin/system/app" */))
const _3f196d26 = () => interopDefault(import('../uicore/pages/arm/admin/org/service.vue' /* webpackChunkName: "pages/arm/admin/org/service" */))
const _97c898b4 = () => interopDefault(import('../uicore/pages/arm/admin/org/employee.vue' /* webpackChunkName: "pages/arm/admin/org/employee" */))
const _3258924e = () => interopDefault(import('../uicore/pages/arm/admin/org/app.vue' /* webpackChunkName: "pages/arm/admin/org/app" */))
const _276ceb74 = () => interopDefault(import('../uicore/pages/base/auth/register.vue' /* webpackChunkName: "pages/base/auth/register" */))
const _ede8e054 = () => interopDefault(import('../uicore/pages/base/auth/profile.vue' /* webpackChunkName: "pages/base/auth/profile" */))
const _5bc916d6 = () => interopDefault(import('../uicore/pages/base/auth/login.vue' /* webpackChunkName: "pages/base/auth/login" */))
const _fda55392 = () => interopDefault(import('../uicore/pages/base/auth/forgotPass.vue' /* webpackChunkName: "pages/base/auth/forgotPass" */))
const _03292e11 = () => interopDefault(import('../uicore/pages/base/application/list.vue' /* webpackChunkName: "pages/base/application/list" */))
const _6de9f0aa = () => interopDefault(import('../uicore/pages/arm/application/list.vue' /* webpackChunkName: "pages/arm/application/list" */))
const _f0ebd196 = () => interopDefault(import('../uicore/pages/arm/application/income.vue' /* webpackChunkName: "pages/arm/application/income" */))
const _3552eec0 = () => interopDefault(import('../uicore/pages/oldAppsList/employee.vue' /* webpackChunkName: "pages/oldAppsList/employee" */))
const _09e95500 = () => interopDefault(import('../uicore/pages/oldAppsList/applicant.vue' /* webpackChunkName: "pages/oldAppsList/applicant" */))
const _d24ff59c = () => interopDefault(import('../uicore/pages/base/faq.vue' /* webpackChunkName: "pages/base/faq" */))
const _5172dd8c = () => interopDefault(import('../uicore/pages/arm/profile.vue' /* webpackChunkName: "pages/arm/profile" */))
const _01f45a0c = () => interopDefault(import('../uicore/pages/arm/login.vue' /* webpackChunkName: "pages/arm/login" */))
const _a28cb67e = () => interopDefault(import('../uicore/pages/arm/forgotPass.vue' /* webpackChunkName: "pages/arm/forgotPass" */))
const _c55b59e8 = () => interopDefault(import('../uicore/pages/Test.vue' /* webpackChunkName: "pages/Test" */))
const _1b645824 = () => interopDefault(import('../uicore/pages/base/index.vue' /* webpackChunkName: "pages/base/index" */))
const _384cb6f5 = () => interopDefault(import('../uicore/pages/arm/index.vue' /* webpackChunkName: "pages/arm/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/base/geoportal",
    component: _21d8717a,
    name: "base-geoportal"
  }, {
    path: "/arm/geoportal",
    component: _ba71ea48,
    name: "arm-geoportal"
  }, {
    path: "/arm/statistica",
    component: _5d439252,
    name: "arm-statistica"
  }, {
    path: "/arm/registry",
    component: _7f3751be,
    name: "arm-registry"
  }, {
    path: "/arm/contacts",
    component: _1ba686d4,
    name: "arm-contacts"
  }, {
    path: "/",
    component: _a348ac30,
    name: "index"
  }, {
    path: "/base/check_doc/:fileId?",
    component: _66db2f38,
    name: "base-check_doc-fileId"
  }, {
    path: "/arm/resetPass/:token?",
    component: _6cffd496,
    name: "arm-resetPass-token"
  }, {
    path: "/base/service/list/:parentId?",
    component: _3542be41,
    name: "base-service-list-parentId"
  }, {
    path: "/base/service/info/:tsrId?",
    component: _5d90ff92,
    name: "base-service-info-tsrId"
  }, {
    path: "/base/auth/resetPass/:token?",
    component: _236bd4e8,
    name: "base-auth-resetPass-token"
  }, {
    path: "/base/application/process/:asId?",
    component: _663a116e,
    name: "base-application-process-asId"
  }, {
    path: "/base/application/monitor/:appId?",
    component: _205d6bb6,
    name: "base-application-monitor-appId"
  }, {
    path: "/arm/service/list/:parentId?",
    component: _b2f92bcc,
    name: "arm-service-list-parentId"
  }, {
    path: "/arm/service/info/:tsrId?",
    component: _53468a59,
    name: "arm-service-info-tsrId"
  }, {
    path: "/arm/application/process/:asId?",
    component: _21c110d0,
    name: "arm-application-process-asId"
  }, {
    path: "/arm/application/monitor/:appId?",
    component: _36b6617e,
    name: "arm-application-monitor-appId"
  }, {
    path: "/arm/admin/org/ws/:tsrId?",
    component: _f480b7cc,
    name: "arm-admin-org-ws-tsrId"
  }, {
    path: "/arm/admin/org/ws/employee/:empId?",
    component: _1d46534a,
    name: "arm-admin-org-ws-employee-empId"
  }, {
    path: "/base/application/result/appId",
    component: _6ea44f1d,
    name: "base-application-result-appId"
  }, {
    path: "/arm/application/view/asId",
    component: _0d2c313b,
    name: "arm-application-view-asId"
  }, {
    path: "/arm/admin/system/route",
    component: _480dec96,
    name: "arm-admin-system-route"
  }, {
    path: "/arm/admin/system/list",
    component: _732d3561,
    name: "arm-admin-system-list"
  }, {
    path: "/arm/admin/system/control",
    component: _5a22872c,
    name: "arm-admin-system-control"
  }, {
    path: "/arm/admin/system/calendar",
    component: _4d7ebafe,
    name: "arm-admin-system-calendar"
  }, {
    path: "/arm/admin/system/app",
    component: _55de91ae,
    name: "arm-admin-system-app"
  }, {
    path: "/arm/admin/org/service",
    component: _3f196d26,
    name: "arm-admin-org-service"
  }, {
    path: "/arm/admin/org/employee",
    component: _97c898b4,
    name: "arm-admin-org-employee"
  }, {
    path: "/arm/admin/org/app",
    component: _3258924e,
    name: "arm-admin-org-app"
  }, {
    path: "/base/auth/register",
    component: _276ceb74,
    name: "base-auth-register"
  }, {
    path: "/base/auth/profile",
    component: _ede8e054,
    name: "base-auth-profile"
  }, {
    path: "/base/auth/login",
    component: _5bc916d6,
    name: "base-auth-login"
  }, {
    path: "/base/auth/forgotPass",
    component: _fda55392,
    name: "base-auth-forgotPass"
  }, {
    path: "/base/application/list",
    component: _03292e11,
    name: "base-application-list"
  }, {
    path: "/arm/application/list",
    component: _6de9f0aa,
    name: "arm-application-list"
  }, {
    path: "/arm/application/income",
    component: _f0ebd196,
    name: "arm-application-income"
  }, {
    path: "/oldAppsList/employee",
    component: _3552eec0,
    name: "oldAppsList-employee"
  }, {
    path: "/oldAppsList/applicant",
    component: _09e95500,
    name: "oldAppsList-applicant"
  }, {
    path: "/base/faq",
    component: _d24ff59c,
    name: "base-faq"
  }, {
    path: "/arm/profile",
    component: _5172dd8c,
    name: "arm-profile"
  }, {
    path: "/arm/login",
    component: _01f45a0c,
    name: "arm-login"
  }, {
    path: "/arm/forgotPass",
    component: _a28cb67e,
    name: "arm-forgotPass"
  }, {
    path: "/Test",
    component: _c55b59e8,
    name: "Test"
  }, {
    path: "/base",
    component: _1b645824,
    name: "base"
  }, {
    path: "/arm",
    component: _384cb6f5,
    name: "arm"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
