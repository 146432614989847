import nca_service from '../assets/js/ncalayer'

export const state = () => ({
    nca: new nca_service(),
    storage: "PKCS12",
    signkey: "SIGNATURE",
    authkey: "AUTH",
})
export const actions = {
    getInfo({ state }) {
        return state.nca.getKeyInfo();
    },
    signXml({ state }, xml) {
        return state.nca.signXml(state.storage, xml == '<authorization></authorization>' ? state.authkey : state.signkey, xml)
            .then(resp => resp.responseObject)
    },
    signCMS({ state }, content) {
        return state.nca.createCMSSignatureFromBase64(
            state.storage,
            state.key,
            content,
            true
        )
    },
}
export const getters = {
    nca: state => state.nca
}