import {
  INIT_MAP,
  GET_LAYERS,
  TOGGLE_BASEMAP,
  SET_BASEMAPS,
  TOGGLE_LAYER,
  GET_OBJECT_DETAILS,
} from "./action-types";
import {
  SET_MAP,
  SET_LAYERS_LOADING,
  SET_LAYERS,
  SET_BASEMAPS as SET_BASEMAPS_MUTATION,
  REMOVE_ACTIVE_LAYER,
  ADD_ACTIVE_LAYER,
  SET_ACTIVE_BASEMAP,
  SET_PANEL_EXPANDED,
  SET_DETAILS_CONTROLLER,
  SET_OBJECT_DETAILS,
  SET_DETAILS_LOADING,
  SET_PANEL_TAB,
} from "./mutation-types";

import _ from "lodash";

import TileLayer from "ol/layer/Tile.js";
import TileWMS from "ol/source/TileWMS.js";

import olInitMap from "~/assets/js/olInitMap";

const createLayer = (layer) =>
  new TileLayer({
    source: new TileWMS({
      url: `${process.env.GEOSERVER_URL}/wms`,
      params: {
        LAYERS: layer,
        TILED: true,
        VERSION: "1.1.1",
        SRS: "EPSG:3857",
        BBOX: "{bbox-epsg-3857}",
        WIDTH: 256,
        HEIGHT: 256,
      },
      serverType: "geoserver",
    }),
    zIndex: 99,
  });

export default {
  [INIT_MAP]({ commit, state }, { el, initialFocus }) {
    const [bId, bObj] = _.chain(state.basemaps)
      .entries()
      .first()
      .defaultTo([])
      .value();
    const map = olInitMap({ el, basemap: bObj?.olLayer, initialFocus });
    commit(SET_MAP, map);
    commit(SET_ACTIVE_BASEMAP, bId);
  },
  [SET_BASEMAPS]({ commit, state }, { basemaps }) {
    const olLayer = state.basemaps?.[state.activeBasemap]?.olLayer;

    state.map.removeLayer(olLayer);

    const [bId, bObj] = _.chain(basemaps)
      .entries()
      .first()
      .defaultTo([])
      .value();
    state.map.addLayer(bObj.olLayer);

    commit(SET_BASEMAPS_MUTATION, basemaps);
    commit(SET_ACTIVE_BASEMAP, bId);
  },
  [GET_LAYERS]({ commit }) {
    commit(SET_LAYERS_LOADING, true);

    return this.$axios
      .$get("api/geoportal/layers")
      .then((response) => {
        const olLayers = _.chain(response ?? [])
          .keyBy((l) => l.title || l.geoserver_view_name)
          .mapValues((layer, layerId) => ({
            ...layer,
            olLayer: createLayer(layerId),
          }))
          .value();

        commit(SET_LAYERS, olLayers);

        return response;
      })
      .catch(() => alert("Ошибка загрузки данных!"))
      .finally(() => commit(SET_LAYERS_LOADING, false));
  },
  [TOGGLE_BASEMAP]({ commit, state }, { basemapId }) {
    const { map, basemaps, activeBasemap } = state;

    if (!(basemapId in basemaps)) return;

    const prevBasemap = basemaps?.[activeBasemap]?.olLayer;
    map.removeLayer(prevBasemap);

    map.addLayer(basemaps[basemapId].olLayer);
    commit(SET_ACTIVE_BASEMAP, basemapId);
  },
  [TOGGLE_LAYER]({ commit, state }, { layerId }) {
    if (!layerId) return;

    const olLayer = state.layers[layerId]?.olLayer;

    if (!state.activeLayers.includes(layerId)) {
      commit(ADD_ACTIVE_LAYER, layerId);
      state.map.addLayer(olLayer);
      return true;
    }

    commit(REMOVE_ACTIVE_LAYER, layerId);
    state.map.removeLayer(olLayer);
  },
  [GET_OBJECT_DETAILS]({ commit, state }, { mapEvent }) {
    if (!state.activeLayers.length) return;

    state.detailRequestController?.abort();

    const controller = new AbortController();

    const coord = mapEvent?.coordinate;
    const layer = state.layers[state.activeLayers[0]];

    const url = layer.olLayer
      .getSource()
      .getFeatureInfoUrl(coord, 0.0005, "EPSG:4326", {
        INFO_FORMAT: "application/json",
        QUERY_LAYERS: state.activeLayers.join(","),
        LAYERS: state.activeLayers.join(","),
      });

    commit(SET_OBJECT_DETAILS, {
      id: null,
      layer: null,
      attributes: null,
    });

    commit(SET_DETAILS_CONTROLLER, controller);
    commit(SET_DETAILS_LOADING, true);

    return this.$axios
      .$get(url, { signal: controller.signal })
      .then((response) => {
        const feature = response?.features?.[0];

        if (!feature) return;

        const layer = feature?.id?.split(".").shift();
        const object_id = feature?.properties?.id;

        commit(SET_PANEL_TAB, "DETAILS");
        commit(SET_PANEL_EXPANDED, true);
        commit(SET_OBJECT_DETAILS, {
          id: object_id,
          layer,
        });

        return this.$axios.$get("api/geoportal/attributes", {
          params: { layer, object_id },
          signal: controller.signal,
        });
      })
      .then((response) => {
        commit(SET_OBJECT_DETAILS, { attributes: response });
        commit(SET_DETAILS_LOADING, false);
      })
      .catch((e) => {
        if (!this.$axios.isCancel(e)) {
          alert("Ошибка загрузки данных");
        }
      })
      .finally(() => commit(SET_DETAILS_CONTROLLER, null));
  },
};
