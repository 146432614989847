export default function ({ redirect, $auth, route }) {
  if (!$auth.loggedIn) {
    return redirect({
      path: "/arm/login",
      query: { redirect: route.fullPath }
    })
  } else if ($auth.user.role != 3) {
    return redirect({
      path: "/arm/login",
      query: { redirect: route.fullPath }
    })
  }
}
