export default () => ({
  incomeApps: null,
  incomeAppsWithPagination: {},
  apps: null,
  appsWithPagination: {},
  paginationActivePage: 1,
  paginationPagesCount: 1,
  selectedAppStatus: "all",
  searchingValue: null,
  selectedAppPeriod: null
});
