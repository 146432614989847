import geoportalStore from "@new_geoportal/store/geoportal";

export default function ({ store }) {
    store.registerModule('geoportal', geoportalStore)

    // Base header
    store.commit("setBaseHeaderMenuItem", {
        to: "/base/geoportal",
        name: "header.geoportal",
    });

    // Arm header
    store.commit("setArmHeaderMenuItem", {
        to: "/arm/geoportal",
        name: "header.geoportal",
    });
}
