export default function ({ $axios, redirect, $auth  }) {
  if (process.client) {
    $axios.setToken('638c856c18d1a0babc081bf6|3bUx1jCXjOSi0IqlM3yFhYPyOCLs2nYf8YcBzWDI', 'Bearer')
  }

  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status)
    if (code === 401 && $auth?.logout && $auth?.logout?.call) {
      $auth.logout();
      redirect("/base/auth/login");
    }
  })
}