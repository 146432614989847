export default function ({ store }) {
    // Arm header
    store.commit("setArmHeaderMenuItem", {
        to: "/arm/registry",
        name: "Реестр заявок",
    });
    store.commit("setArmHeaderMenuItem", {
        to: "/arm/contacts",
        name: "header.contacts",
    });
    store.commit("setArmHeaderMenuItem", {
        to: "/arm/statistica",
        name: "Статистика",
    });
}
