export default (context, inject) => {

    inject('getPaginatedData', async (uri, statedPage = null, params = {}, method = 'get') => {
        const getData = (method, uri, params) => {
            return context.$axios['$' + method](
                uri, {params: params}
            );
        }

        const getPaginationState = () => {
            return JSON.parse(
                localStorage.paginationState ?? '{}'
            );
        }

        const savePaginationState = (paginationState) => {
            localStorage.paginationState = JSON.stringify(paginationState);
        }

        let paginationState = getPaginationState();
        params.page = statedPage ?? paginationState[uri] ?? 1;
        let lastResponse = await getData(method, uri, params);

        if (lastResponse.meta.last_page < params.page) {
            params.page = lastResponse.meta.last_page;
            lastResponse = await getData(method, uri, params);
        }

        paginationState[uri] = params.page;
        savePaginationState(paginationState);

        return lastResponse;
    });

}