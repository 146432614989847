export const UPDATE_FIELD_VALUE = 'UPDATE_FIELD_VALUE';
export const UPDATE_TABLE_FIELD_VALUE = 'UPDATE_TABLE_FIELD_VALUE';
export const GET_WS_DATA = 'GET_WS_DATA';
export const FILL_WS_DATA = 'FILL_WS_DATA';
export const POST_WS_DATA = 'POST_WS_DATA';
export const RETURN_WS_DATA = 'RETURN_WS_DATA';
export const GET_CATALOG = 'GET_CATALOG';
export const GET_REGISTRY_CATALOG = 'GET_REGISTRY_CATALOG';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const REMOVE_FILE = 'REMOVE_FILE';
export const SIGN_FILE = 'SIGN_FILE';
export const SET_ERRORMESSAGE_BY_ID = 'SET_ERRORMESSAGE_BY_ID';
