export default class NcalayerService {
  constructor() {
    this.defaults = {
      storageName: "PKCS12",
      keyType: "SIGNATURE",
      additionDataFlag: false,
      currentDirectory: ""
    }
  }

  _createConnection() {
    this.webSocket = new WebSocket("wss://127.0.0.1:13579/");

    this.webSocket.onopen = event => {
      if (this.nextAction) {
        this.nextAction();
      }
    };

    this.webSocket.onclose = event => {
      this._onWebSocketClose(event);
    };

    this.webSocket.onmessage = event => {
      this._onWebSocketMessage(event);
    };

    this.webSocket.onerror = event => {
      this._onWebSocketError(event);
    };
  }

  _onWebSocketMessage(event) {
    const result = JSON.parse(event.data);

    if (result == null) {
      return
    }

    if (this.nextAction) {
      this.nextAction(result);
    }
  }

  _onWebSocketClose(event) {
    // if (event.wasClean) {
    //   console.log("connection has been closed");
    // } else {
    //   console.log("Connection error");

    //   const confirm_message =
    //     "Ошибка при подключении к NCALayer. " +
    //     "Запустите NCALayer и нажмите ОК";

    //   alert(confirm_message);

      // if (confirm(confirmMessage) === true) {
      //   location.reload()
      // }
    // }

    // console.log("Code: " + event.code + " Reason: " + event.reason);
  }

  _onWebSocketError(event) {
    alert('Требуется запустить NCALayer !')
  }

  _clearNextAction() {
    this.nextAction = null;
  }

  _getData(ncaModule, method, args) {
    const that = this;
    const params = { method, args };

    if (ncaModule)
      Object.assign(params, {
        module: ncaModule
      });

    const paramString = JSON.stringify(params);

    return new Promise((resolve, reject) => {
      if (that.webSocket && that.webSocket.readyState === 1) {
        that.nextAction = response => {
          if (response.code === "500") reject(response);

          if (response.code === "200") {
            resolve(response);

            that._clearNextAction();
          }
        };

        that.webSocket.send(paramString);
      } else {
        setTimeout(() => {
          this.nextAction = () => {
            that._getData(ncaModule, method, args).then(
              response => {
                resolve(response);
              },
              error => {
                reject(error);
              }
            )
          };

          that._createConnection();
        }, 1000);
      }

    })
  }

  _isValidityDate(response) {
    if (!response.responseObject) {
      return;
    }

    const { certNotBefore: start, certNotAfter: end } = response.responseObject;

    const curDate = new Date().valueOf();

    if (curDate > +start && curDate < +end) {
      return true;
    }

    response.message = "Срок действия ключа истек или еще не наступил.";
  }

  getActiveTokens() {
    const ncaModule = "kz.gov.pki.knca.commonUtils";
    const method = "getActiveTokens";

    return this._getData(ncaModule, method);
  }

  getKeyInfo(storageName = this.defaults.storageName) {
    const ncaModule = "kz.gov.pki.knca.commonUtils";
    const method = "getKeyInfo";
    const args = [storageName];

    return this._getData(ncaModule, method, args).then(response => {
      const result = response.responseObject;

      result.subject = this._normalizeSubjectInfo(result.subjectDn);

      if (!this._isValidityDate(response)) {
        return throwError(response)
      }

      return result
    })
  }

  _normalizeSubjectInfo(sdn) {
    const sdnRawAttr = sdn.split(",");
    const sdnAttr = {};
    const attrMap = {
      SERIALNUMBER: "iin",
      CN: "cn",
      OU: "bin",
      E: "email",
      G: "middleName",
      O: "orgName"
    };

    sdnRawAttr.forEach(it => {
      const [key, val] = it.split("=");

      if (key in attrMap) {
        sdnAttr[attrMap[key]] = val
      }
    });

    sdnAttr.iin = sdnAttr.iin.substr(3);
    sdnAttr.userName = [sdnAttr.cn, sdnAttr.middleName].join(" ");
    sdnAttr.surname = sdnAttr.userName.split(" ")[0]; 
    sdnAttr.name = sdnAttr.userName.split(" ")[1]; 
    sdnAttr.patronym = sdnAttr.middleName; 

    if (sdnAttr.bin) {
      if (sdnAttr.bin.length > 3) {
        sdnAttr.bin = sdnAttr.bin.substr(3)
      }

      sdnAttr.orgName = sdnAttr.orgName.replace(/\\\"/g, '"')
    }

    return sdnAttr
  }

  signXml(
    storageName = this.defaults.storageName,
    keyType = this.defaults.keyType,
    xmlToSign
  ) {
    const ncaModule = "kz.gov.pki.knca.commonUtils";
    const method = "signXml";
    const args = [storageName, keyType, xmlToSign, "", ""];

    return this._getData(ncaModule, method, args)
  }

  signXmls(
    storageName = this.defaults.storageName,
    keyType = this.defaults.keyType,
    xmlsToSign
  ) {
    const ncaModule = "kz.gov.pki.knca.commonUtils";
    const method = "signXmls";
    const args = [storageName, keyType, xmlsToSign, "", ""];

    return this._getData(ncaModule, method, args);
  }

  createCAdESFromFile(
    storageName = this.defaults.storageName,
    keyType = this.defaults.keyType,
    filePath,
    flag = this.defaults.additionDataFlag
  ) {
    const ncaModule = "kz.gov.pki.knca.commonUtils";
    const method = "createCAdESFromFile";
    const args = [storageName, keyType, filePath, flag];

    return this._getData(ncaModule, method, args)
  }

  createCAdESFromBase64(
    storageName = this.defaults.storageName,
    keyType = this.defaults.keyType,
    base64ToSign,
    flag = this.defaults.additionDataFlag
  ) {
    const ncaModule = "kz.gov.pki.knca.commonUtils";
    const method = "createCAdESFromBase64";
    const args = [storageName, keyType, base64ToSign, flag];

    return this._getData(ncaModule, method, args)
  }

  createCAdESFromBase64Hash(
    storageName = this.defaults.storageName,
    keyType = this.defaults.keyType,
    base64ToSign
  ) {
    const ncaModule = "kz.gov.pki.knca.commonUtils";
    const method = "createCAdESFromBase64Hash";
    const args = [storageName, keyType, base64ToSign];

    return this._getData(ncaModule, method, args)
  }

  applyCAdEST(
    storageName = this.defaults.storageName,
    keyType = this.defaults.keyType,
    cmsForTS
  ) {
    const ncaModule = "kz.gov.pki.knca.commonUtils";
    const method = "applyCAdEST";
    const args = [storageName, keyType, cmsForTS];

    return this._getData(ncaModule, method, args)
  }

  showFileChooser(
    fileExtension,
    currentDirectory = this.defaults.currentDirectory
  ) {
    const ncaModule = "kz.gov.pki.knca.commonUtils";
    const method = "showFileChooser";
    const args = [fileExtension, currentDirectory];

    return this._getData(ncaModule, method, args)
  }

  changeLocale(language) {
    const ncaModule = "kz.gov.pki.knca.commonUtils";
    const method = "changeLocale";
    const args = [language];

    this._getData(ncaModule, method, args).then(() => {})
  }

  createCMSSignatureFromFile(
    storageName = this.defaults.storageName,
    keyType = this.defaults.keyType,
    filePath,
    flag = this.defaults.additionDataFlag
  ) {
    const ncaModule = "kz.gov.pki.knca.commonUtils";
    const method = "createCMSSignatureFromFile";
    const args = [storageName, keyType, filePath, flag];

    return this._getData(ncaModule, method, args)
  }

  createCMSSignatureFromBase64(
    storageName = this.defaults.storageName,
    keyType = this.defaults.keyType,
    base64ToSign,
    flag = this.defaults.additionDataFlag
  ) {
    const ncaModule = "kz.gov.pki.knca.commonUtils";
    const method = "createCMSSignatureFromBase64";
    const args = [storageName, keyType, base64ToSign, flag];

    return this._getData(ncaModule, method, args)
  }

  convertBase64ToArrayBuffer(base64) {
    if (!base64) {
      return
    }

    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i)
    }

    return bytes.buffer;
  }
}
