import Vue from 'vue';
import {
  SET_MAP,
  SET_LAYERS_LOADING,
  SET_BASEMAPS,
  SET_ACTIVE_BASEMAP,
  SET_LAYERS,
  ADD_ACTIVE_LAYER,
  REMOVE_ACTIVE_LAYER,
  SET_PANEL_EXPANDED,
  SET_PANEL_TAB,
  SET_DETAILS_LOADING,
  SET_DETAILS_CONTROLLER,
  SET_OBJECT_DETAILS,
} from './mutation-types'

export default {
  [SET_MAP](state, map) {
    state.map = map;
  },
  [SET_LAYERS_LOADING](state, isLoading) {
    state.layersLoading = isLoading;
  },
  [SET_BASEMAPS](state, basemaps) {
    state.basemaps = basemaps;
  },
  [SET_ACTIVE_BASEMAP](state, basemapId) {
    state.activeBasemap = basemapId;
  },
  [SET_LAYERS](state, layers) {
    state.layers = layers;
  },
  [ADD_ACTIVE_LAYER](state, layerId) {
    state.activeLayers.push(layerId);
  },
  [REMOVE_ACTIVE_LAYER](state, layerId) {
    const idx = state.activeLayers.findIndex(i => i === layerId);
    state.activeLayers.splice(idx, 1);
  },
  [SET_PANEL_EXPANDED](state, expanded) {
    state.panelExpanded = expanded;
    setTimeout(() => state.map.updateSize(), 300);
  },
  [SET_PANEL_TAB](state, tab) {
    state.panelTab = tab;
  },
  [SET_DETAILS_LOADING](state, isLoading) {
    state.detailsLoading = isLoading;
  },
  [SET_DETAILS_CONTROLLER](state, controller) {
    state.detailRequestController = controller;
  },
  [SET_OBJECT_DETAILS](state, details) {
    state.objectDetails = {
      ...state.objectDetails,
      ...details,
    };
  },
}
