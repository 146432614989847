
import {
  SET_INCOME_APPS,
  SET_APPS,
  SET_STATUS,
  SET_SEARCHING_VALUE,
  SET_PERIOD,
  SET_ACTIVE_PAGE,
  SET_PAGINATION_PAGES_COUNT,
  SET_INCOME_APPS_WITH_PAGINATION,
  SET_APPS_WITH_PAGINATION
} from "./mutation-types";

export default {
  [SET_INCOME_APPS](state, incomeApps) {
    state.incomeApps = incomeApps;
  },
  [SET_APPS](state, apps) {
    state.apps = apps;
  },
  [SET_INCOME_APPS_WITH_PAGINATION](state, incomeAppsWithPagination) {
    state.incomeAppsWithPagination = incomeAppsWithPagination;
  },
  [SET_APPS_WITH_PAGINATION](state, appsWithPagination) {
    state.appsWithPagination = appsWithPagination;
  },
  [SET_STATUS](state, selectedAppStatus) {
    state.selectedAppStatus = selectedAppStatus;
  },
  [SET_SEARCHING_VALUE](state, searchingValue) {
    state.searchingValue = searchingValue;
  },
  [SET_PERIOD](state, selectedAppPeriod) {
    state.selectedAppPeriod = selectedAppPeriod;
  },
  [SET_ACTIVE_PAGE](state, activePage) {
    state.paginationActivePage = activePage;
  },
  [SET_PAGINATION_PAGES_COUNT](state, pagesCount) {
    state.paginationPagesCount = pagesCount;
  }
};
