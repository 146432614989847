export const SET_MAP = 'SET_MAP';
export const SET_LAYERS_LOADING = 'SET_LAYERS_LOADING';
export const SET_BASEMAPS = 'SET_BASEMAPS';
export const SET_ACTIVE_BASEMAP = 'SET_ACTIVE_BASEMAP';
export const SET_LAYERS = 'SET_LAYERS';
export const ADD_ACTIVE_LAYER = 'ADD_ACTIVE_LAYER';
export const REMOVE_ACTIVE_LAYER = 'REMOVE_ACTIVE_LAYER';
export const SET_PANEL_EXPANDED = 'SET_PANEL_EXPANDED';
export const SET_PANEL_TAB = 'SET_PANEL_TAB';
export const SET_DETAILS_LOADING = 'SET_DETAILS_LOADING';
export const SET_DETAILS_CONTROLLER = 'SET_DETAILS_CONTROLLER';
export const SET_OBJECT_DETAILS = 'SET_OBJECT_DETAILS';
